<template>
  <container class="collectables-list py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">collectables</h1>
    <div class="mb-16">
      <button class="button primary mx-auto w-full md:w-96" @click="createNew">
        Add New
      </button>
    </div>
    <div class="flex justify-start items-center">
      <toggle
        :value="filterNft"
        class="text-tag-nft mr-8"
        @onChange="handleNftToggle($event)"
      >
        <span class="font-bold ml-1 text-black">NFT</span>
      </toggle>

      <toggle
        :value="filterTangibleNft"
        class="text-tag-tangible"
        @onChange="handleTangibleToggle($event)"
      >
        <span class="font-bold ml-1 text-black">NFT + Tangible</span>
      </toggle>
    </div>
    <div
        class="auction-list-big grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 mt-5"
      >
        <template
          v-for="collectable in listOfCollectables"
          :key="collectable && collectable.id"
        >
          <product-card
            v-if="collectable != null"
            :collectable="collectable"
            @click="editCollectable(collectable.contract_address ? collectable.contract_address : collectable.id)"
          />
          <div
            v-else
            class="placeholder-card overflow-hidden rounded-3xl bg-gray-100"
            :style="{ 'padding-bottom': '120%' }"
          ></div>
        </template>
      </div>

      <button
        class="button dark mt-20 mx-auto w-full md:w-96"
        v-if="hasMore"
        @click="handleLoadMore"
      >
        Load More
      </button>
  </container>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { computed, reactive } from "vue";

import Toggle from "@/components/Inputs/Toggle.vue";
import Container from "@/components/Container.vue";
import ProductCard from "@/components/ProductCard.vue";

import PURCHASE_TYPE from "@/constants/PurchaseTypes.js";
import useCollectablesWithPagination from "@/hooks/useCollectablesWithPagination.js";

export default {
  name: "Collectables",
  components: {
    Container,
    Toggle,
    ProductCard,
  },
  setup() {
    const state = reactive({
      filterNft: true,
      filterTangibleNft: true,
    });

    function createNew() {
      router.push('/collectables/new');
    }

    const filterNft = computed(() => state.filterNft);
    const filterTangibleNft = computed(() => state.filterTangibleNft);

    const paginatedCollectables = useCollectablesWithPagination(
      PURCHASE_TYPE.AUCTION
    );
    const listOfCollectables = computed(
      () => paginatedCollectables.listOfCollectables.value
    );
    const hasMore = computed(() => paginatedCollectables.hasMore.value);

    paginatedCollectables.load();

    const handleLoadMore = async () => {
      paginatedCollectables.loadMore();
    };

    const handleNftToggle = function (event) {
      state.filterNft = event;
      paginatedCollectables.filter(state.filterNft, state.filterTangibleNft);
    };

    const handleTangibleToggle = function (event) {
      state.filterTangibleNft = event;
      paginatedCollectables.filter(state.filterNft, state.filterTangibleNft);
    };

    const list = ref([1, 2, 3, 4, 5]);
    const router = useRouter();

    function editCollectable(contractAddress) {
      router.push({
        name: "collectable",
        params: { contractAddress: contractAddress },
      });
    }

    return {
      list,
      editCollectable,
      handleTangibleToggle,
      handleNftToggle,
      handleLoadMore,
      hasMore,
      filterTangibleNft,
      filterNft,
      listOfCollectables,
      createNew,
    };
  },
};
</script>

<style scoped>
</style>
